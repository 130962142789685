@import "./mixin";
@import "./breakpoints";
@import "./landingPage";
@import "./aboutMe";
@import "./techSkills";
@import "./whyMe";
@import "./contact";
@import "./header";

*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}

.App{
    font-family:Georgia, 'Times New Roman', Times, serif;
    width: 100%;
    height: 100vh;
    min-height: 600px;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    max-width: 100%;
    margin: auto;
    background-image: url("../components/assets/images/IMG_5940.JPG");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    
}
section{
    @include flexCenter;
    width: 100%;
    height: 100%;
    scroll-snap-align: start;
    color:white;
}