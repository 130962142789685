@import "./breakpoints";
@mixin flexCenter{
    display: flex;
    justify-content: center;
    align-items: center;
}
@mixin glassEffect{
    width: 85%;
    height: 90%;
    margin: auto auto;
    background-color: rgb(255, 255, 255);
    backdrop-filter:blur(13px);
    background: inherit;
    box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.15);
    border: none;
    border-radius: 10px;
    overflow: hidden;
    &:before{
        background: inherit;
    }
    @include md{
        width: 90%;
        height: 85%;
    }

}
@mixin btnLinks{
    text-decoration: none;
    background-color: rgba(255, 255, 255, 0.911);
    padding: 5px;
    color: black;
    margin-right: 5px ;
    border: none;
    border-radius: 5px;
}