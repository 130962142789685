$breakpoints: (
    "xs": 0,
    "sm": 480px,
    "md": 720px,
    "lg": 960px,
    "xl": 1200px
);
@mixin xs {
    @media(min-width: map-get($breakpoints, "xs")){
        @content;
    }
}
@mixin sm {
    @media(min-width: map-get($breakpoints, "sm")){
        @content;
    }
}
@mixin md {
    @media(min-width: map-get($breakpoints, "md")){
        @content;
    }
}
@mixin lg {
    @media(min-width: map-get($breakpoints, "lg")){
        @content;
    }
}
@mixin xl {
    @media(min-width: map-get($breakpoints, "xl")){
        @content;
    }
}
@mixin breakpoiny($bp:0){
    @media(min-width: $bp){
        @content;
    }
}