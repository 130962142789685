.landing-page {
  width: 85%;
  height: 90%;
  margin: auto auto;
  background-color: rgb(255, 255, 255);
  backdrop-filter: blur(13px);
  background: inherit;
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.15);
  border: none;
  border-radius: 10px;
  overflow: hidden;
}
.landing-page:before {
  background: inherit;
}
@media (min-width: 720px) {
  .landing-page {
    width: 90%;
    height: 85%;
  }
}
.landing-page .landing-page-container {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 5%;
}
@media (min-width: 960px) {
  .landing-page .landing-page-container {
    padding-left: 10%;
  }
}
@media (min-width: 1200px) {
  .landing-page .landing-page-container h2, .landing-page .landing-page-container h3 {
    font-size: 40px;
  }
  .landing-page .landing-page-container h1 {
    font-size: 50px;
  }
}
.landing-page a {
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.911);
  padding: 5px;
  color: black;
  margin-right: 5px;
  border: none;
  border-radius: 5px;
}
.landing-page img {
  display: none;
}
@media (min-width: 720px) {
  .landing-page img {
    display: inline;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 55%;
  }
}
@media (min-width: 960px) {
  .landing-page img {
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .landing-page img {
    width: 40%;
  }
}

.about-me {
  width: 85%;
  height: 90%;
  margin: auto auto;
  background-color: rgb(255, 255, 255);
  backdrop-filter: blur(13px);
  background: inherit;
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.15);
  border: none;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.about-me:before {
  background: inherit;
}
@media (min-width: 720px) {
  .about-me {
    width: 90%;
    height: 85%;
  }
}
.about-me .image-wrapper {
  display: none;
  width: 45%;
  height: 30%;
}
.about-me .image-wrapper img {
  width: 100%;
}
.about-me .summary-wrapper {
  width: 90%;
  height: 70%;
  font-size: smaller;
}
.about-me a {
  color: white;
}
@media (min-width: 480px) {
  .about-me .summary-wrapper {
    height: 60%;
  }
}
@media (min-width: 720px) {
  .about-me {
    flex-direction: row;
  }
  .about-me .summary-wrapper {
    width: 60%;
    height: auto;
    padding: 3%;
    font-size: medium;
  }
  .about-me .summary-wrapper h2 {
    font-size: 40px;
  }
  .about-me .image-wrapper {
    display: inline;
    width: 40%;
    height: 100%;
    position: relative;
  }
  .about-me .image-wrapper img {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
@media (min-width: 960px) {
  .about-me .summary-wrapper {
    font-size: large;
  }
  .about-me .summary-wrapper h2 {
    font-size: 50px;
  }
}
@media (min-width: 1200px) {
  .about-me .summary-wrapper {
    width: 70%;
    font-size: larger;
  }
  .about-me .image-wrapper {
    width: 30%;
  }
}

.tech-skills-container {
  width: 85%;
  height: 90%;
  margin: auto auto;
  background-color: rgb(255, 255, 255);
  backdrop-filter: blur(13px);
  background: inherit;
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.15);
  border: none;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.tech-skills-container:before {
  background: inherit;
}
@media (min-width: 720px) {
  .tech-skills-container {
    width: 90%;
    height: 85%;
  }
}
.tech-skills-container .tech-skills-content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 40%;
  padding: 5%;
}
.tech-skills-container .tech-logo-wrapper {
  background-color: rgba(0, 0, 0, 0.603);
  width: 100%;
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.tech-skills-container .tech-logo-wrapper .tech-logo-card {
  font-size: x-small;
  width: 25%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-content: space-between;
}
.tech-skills-container .tech-logo-wrapper .tech-logo-card img {
  width: 40%;
  height: 60%;
}
.tech-skills-container .tech-logo-wrapper .tech-logo-card p {
  height: 30%;
  justify-self: flex-end;
}
@media (min-width: 720px) {
  .tech-skills-container .tech-logo-wrapper .tech-logo-card {
    width: 20%;
  }
  .tech-skills-container .tech-logo-wrapper .tech-logo-card img {
    height: 80%;
  }
}
@media (min-width: 960px) {
  .tech-skills-container .tech-skills-content-wrapper {
    font-size: larger;
    padding: 4rem;
  }
  .tech-skills-container .tech-skills-content-wrapper h2 {
    font-size: 50px;
  }
  .tech-skills-container .tech-logo-wrapper .tech-logo-card {
    width: 15%;
    height: 22%;
  }
  .tech-skills-container .tech-logo-wrapper .tech-logo-card img {
    height: 90%;
  }
}
@media (min-width: 1200px) {
  .tech-skills-container .tech-logo-wrapper .tech-logo-card {
    width: 12%;
    height: 25%;
  }
  .tech-skills-container .tech-logo-wrapper .tech-logo-card img {
    width: 40%;
  }
}

.why-me-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 85%;
  height: 90%;
  margin: auto auto;
  background-color: rgb(255, 255, 255);
  backdrop-filter: blur(13px);
  background: inherit;
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.15);
  border: none;
  border-radius: 10px;
  overflow: hidden;
}
.why-me-container:before {
  background: inherit;
}
@media (min-width: 720px) {
  .why-me-container {
    width: 90%;
    height: 85%;
  }
}
.why-me-container ul {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  width: 100%;
  padding: 0 1rem;
}
.why-me-container ul li {
  font-size: small;
  width: 100%;
}
.why-me-container ul li img {
  width: 30px;
  height: 30px;
}
@media (min-width: 720px) {
  .why-me-container ul li {
    font-size: medium;
    width: 50%;
    height: 50%;
    padding: 1rem;
    border-left: 1px solid white;
  }
  .why-me-container ul li img {
    width: 50px;
    height: 50px;
  }
}
@media (min-width: 960px) {
  .why-me-container h1 {
    font-size: 50px;
  }
  .why-me-container ul {
    height: 80%;
  }
  .why-me-container ul li {
    background-color: rgba(0, 0, 0, 0.164);
    margin: 1%;
    width: 22%;
    height: 70%;
    border: none;
    box-shadow: 0 0 5px 2px rgba(189, 189, 189, 0.596);
  }
  .why-me-container ul li:hover {
    box-shadow: 0 0 7px 5px rgba(189, 189, 189, 0.596);
  }
  .why-me-container ul .li-even {
    align-self: flex-start;
  }
  .why-me-container ul .li-odd {
    align-self: flex-end;
  }
}

.contact-container {
  width: 100%;
  width: 85%;
  height: 90%;
  margin: auto auto;
  background-color: rgb(255, 255, 255);
  backdrop-filter: blur(13px);
  background: inherit;
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.15);
  border: none;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.contact-container:before {
  background: inherit;
}
@media (min-width: 720px) {
  .contact-container {
    width: 90%;
    height: 85%;
  }
}
.contact-container .contact-content-wrapper {
  height: 30%;
  padding: 5%;
}
.contact-container .contact-content-wrapper h1 {
  display: none;
}
.contact-container .contact-content-wrapper a {
  color: white;
}
.contact-container .contact-formik-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70%;
  background-color: rgba(0, 0, 0, 0.678);
  padding: 5%;
}
.contact-container .contact-formik-wrapper .form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: auto 5%;
}
.contact-container .contact-formik-wrapper .form-container input {
  width: 100%;
  margin: 1%;
  padding: 5px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.918);
  color: black;
}
.contact-container .contact-formik-wrapper .form-container #contact-formik-btn {
  width: 99%;
  color: white;
  background-color: rgba(79, 49, 82, 0.884);
}
.contact-container .contact-formik-wrapper .form-container #message {
  height: 4rem;
}
@media (min-width: 480px) {
  .contact-container .contact-formik-wrapper .form-container {
    padding: auto 15%;
  }
}
@media (min-width: 720px) {
  .contact-container {
    flex-direction: row;
  }
  .contact-container .contact-content-wrapper {
    width: 50%;
    height: 60%;
    align-self: flex-end;
  }
  .contact-container .contact-content-wrapper h1 {
    display: block;
    font-size: 50px;
  }
  .contact-container .contact-formik-wrapper {
    width: 50%;
    height: 100%;
  }
  .contact-container .contact-formik-wrapper .form-container h1 {
    visibility: hidden;
    width: 100%;
  }
  .contact-container .contact-formik-wrapper .form-container h1::before {
    content: "text me";
    color: white;
    visibility: visible;
    font-size: 25px;
  }
  .contact-container .contact-formik-wrapper .form-container #message {
    height: 6rem;
  }
}
@media (min-width: 960px) {
  .contact-container .contact-content-wrapper {
    font-size: larger;
  }
  .contact-container .contact-content-wrapper h1 {
    font-size: 60px;
  }
  .contact-container .contact-formik-wrapper .form-container {
    width: 87%;
  }
  .contact-container .contact-formik-wrapper .form-container input {
    padding: 10px;
  }
  .contact-container .contact-formik-wrapper .form-container #message {
    height: 8rem;
  }
  .contact-container .contact-formik-wrapper .form-container h1::after {
    font-size: 50px;
  }
}

.header-layout {
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 40px;
}
.header-layout a {
  display: none;
  background-color: transparent;
  color: white;
  font-size: larger;
}
.header-layout #basic-button {
  display: inline;
  position: absolute;
  left: 0;
  top: 0;
  color: white;
}
@media (min-width: 720px) {
  .header-layout a {
    display: inline;
  }
  .header-layout #basic-button {
    display: none;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  font-family: Georgia, "Times New Roman", Times, serif;
  width: 100%;
  height: 100vh;
  min-height: 600px;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  max-width: 100%;
  margin: auto;
  background-image: url("../components/assets/images/IMG_5940.JPG");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  scroll-snap-align: start;
  color: white;
}

