@import "./breakpoints";
@import "./mixin";
.tech-skills-container{
    @include glassEffect;
    @include flexCenter;
    flex-direction: column;
    .tech-skills-content-wrapper{
        @include flexCenter;
        flex-direction: column;
        width: 100%;
        height: 40%;
        padding: 5%;
    }
    .tech-logo-wrapper{
        background-color: rgba(0, 0, 0, 0.603);
        width: 100%;
        height: 60%;
        @include flexCenter;
        flex-wrap: wrap;
        .tech-logo-card{
            font-size: x-small;
            width: 25%;
            height: 20%;
            @include flexCenter;
            flex-direction: column;
            align-content: space-between;
            img{
                width: 40%;
                height: 60%;
            }
            p{
                height: 30%;
                justify-self: flex-end;
            }
        }
    }
    @include md{
        .tech-logo-wrapper{
            .tech-logo-card{
                width: 20%;
                img{
                    height: 80%;
                }
            }
        }
    }
    @include lg{
        .tech-skills-content-wrapper{
            font-size: larger;
            padding: 4rem;
            h2{
                font-size: 50px;
            }
        }
        .tech-logo-wrapper{
            .tech-logo-card{
                width: 15%;
                height: 22%;
                img{
                    height: 90%;
                }
            }
        }
    }
    @include xl{
        .tech-logo-wrapper{
            .tech-logo-card{
                width: 12%;
                height: 25%;
                img{
                    width: 40%;
                }
            }
        }
    }
}
