@import "./mixin";
@import "./breakpoints";

.contact-container{
    width: 100%;
    @include glassEffect;
    @include flexCenter;
    flex-direction: column;
    .contact-content-wrapper{
        height: 30%;
        padding: 5%;
        h1{
            display: none
        }
        a{
            color: white;
        }
    }
    .contact-formik-wrapper{
        @include flexCenter;
        width: 100%;
        height: 70%;
        background-color: rgba(0, 0, 0, 0.678);
        padding: 5%;
        .form-container{
            @include flexCenter;
            flex-direction: column;
            width: 100%;
            padding: auto 5%;
            input{
                width: 100%;
                margin: 1%;
                padding: 5px;
                border-radius: 5px;
                background-color: rgba(255, 255, 255, 0.918);
                color:black;
            }
            #contact-formik-btn{
                width: 99%;
                color:white;
                background-color: rgba(79, 49, 82, 0.884);
            }
            #message{
                height: 4rem;
            }
            
        }
    }
    @include sm{
        .contact-formik-wrapper{
            .form-container{
                padding: auto 15%;
            }
        }
    }
    @include md{
        flex-direction: row;
        .contact-content-wrapper{
            width: 50%;
            height: 60%;
            align-self: flex-end;
            h1{
                display: block;
                font-size: 50px;
            }
        }
        .contact-formik-wrapper{
            width: 50%;
            height: 100%;
            .form-container{
                h1{
                    visibility: hidden;
                    width: 100%;
                    &::before{
                        content: "text me";
                        color:white;
                        visibility: visible;
                        font-size: 25px;
                    }
                }
                #message{
                    height: 6rem;
                }
            }
            
        }
    }
    @include lg{
        .contact-content-wrapper{
            font-size: larger;
            h1{
                font-size: 60px;
            }
        }
        .contact-formik-wrapper{
            .form-container{
                width: 87%;
                input{
                    padding: 10px;
                }
                #message{
                    height: 8rem;
                }
                h1{
                    &::after{
                        font-size: 50px;
                    }
                }
            }
            
        }
    }
}