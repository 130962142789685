@import "./breakpoints";
.landing-page{
   @include glassEffect;
   .landing-page-container{
    position: relative;
    height: 100%;
    @include flexCenter;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 5%;
    @include lg{
        padding-left: 10%;
    }
    @include xl{
        h2, h3{
            font-size: 40px;
        }
        h1{
            font-size: 50px;
        }
    }
   }
   a{
    @include btnLinks;
   }
   img{
    display: none;
    @include md{
        display: inline;
        position: absolute;
        right:0;
        bottom: 0;
        width: 55%;
    }
    @include lg{
        width: 50%;
    }
    @include xl{
        width: 40%;
    }
   }
   
}