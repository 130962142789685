@import "./breakpoints";
@import "./mixin";
.header-layout{
    position: relative;
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap:40px;
    a{
        display: none;
        background-color: transparent;
        color:white;
        font-size: larger;
    }
    #basic-button{
        display: inline;
        position: absolute;
        left: 0;
        top:0;
        color:white;
    }
    @include md{
        a{
            display: inline;
        }
        #basic-button{
            display: none;
        }
    }

}